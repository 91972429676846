@import '../styles/common/fonts.scss';
@import '../styles/common/media-queries.scss';
@import '../styles/common/rtl.scss';
html,
body {
  
  padding: 0;
  margin: 0;
  @include rtl(font-family,NotoSans ,GE_SS_Two);
  .en{
    font-family:NotoSans;
  }
  .ar{
    font-family:GE_SS_Two;
  }
  * {
    scrollbar-width: auto;
    scrollbar-color: #000000 #E5E5E5;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
    @include res-down-high(sm){
      width: 0;
    }
  }

  *::-webkit-scrollbar-track {
    background: #E5E5E5;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 10px;
    border: 0 solid #ffffff;
  }
  h1,h2,h3,h4,h5,h6{
    @include rtl(font-family,PlayfairDisplay ,GE_SS_Two);
  }
  .btn-min,.btn_min{
    min-width: 150px;
  }
  .pageTitle {
    margin-bottom: 240px;
    @include res-down-high(sm){
      font-size: 1.5rem;
      margin-bottom: 60px;
    }
  }
  .page-Title{
    margin: 0;
    font-size: 90px;
    @include res-down-high(sm){
      font-size: 1.8rem;
      margin-top: 10px;
    }
  }
  .page-SubTitle{
    text-transform: uppercase;
    @include res-down-high(sm){
      font-size: 1rem;
    }
  }
  .page-Title,.page-SubTitle{
    @include rtl(font-family,NotoSans ,GE_SS_Two);
  }
}
html{
  overflow-y: auto;
}
body{
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  color:#000;
}

* {
  box-sizing: border-box;
}

.d-block{
  display: block;
}
.d-flex{
  display: flex;
}
.d-none{
  display: none;
}
@include res-up(sm){
  .d-sm-block{
    display: block;
  }
  .d-sm-flex{
    display: flex;
  }
  .d-sm-none{
    display: none;
  }
}
@include res-down-high(sm){
  .d-block{
    display: block;
  }
  .d-flex{
    display: flex;
  }
  .d-none{
    display: none;
  }
}

.MuiDrawer-paper{
  .MuiList-root{
    .MuiButtonBase-root{
      &.active{
        color:#000;
      }
    }
  }
}


.dialog-read{
  .dialog-title{
    display: flex;
    justify-content: space-between;
    line-height: 1.3;
    padding: 85px 95px 25px;
    @include res-down-high(sm){
      padding: 25px 30px 15px;
    }
    .text{
      @include rtl(font-family,NotoSans ,GE_SS_Two);
      font-size: 35px;
      color: #000000;
      line-height: 1.3;
      font-weight: 300;
      @include res-down-high(sm){
        font-size: 30px;
      }
    }
    .btn{
      position: absolute;
      right:16px;
      top: 16px;
      z-index: 9;
    }
  }
  .dialog-content{
    padding: 0 95px 12px;
    border:0;
    @include res-down-high(sm){
      padding: 0 25px 12px;
    }
    p{
      @include rtl(font-family,NotoSans ,GE_SS_Two);
      font-size: 16px;
      line-height: 1.3;
      color: #000000;
      margin-bottom: 20px;
      margin-top: 0;
      @include res-down-high(sm){
        margin-top: 0;
        font-size: 14px;
      }
    }
  }
  .dialog-action{
    padding: 30px 95px 0;
    display: flex;
    flex-direction: column;
    @include res-down-high(sm){
      padding: 30px 25px 0px;
    }
    .btn-play-black{
      display: none;
    }
  }
}


.img_fluid{
  max-width: 100%;
  height: auto;
}

.video_banner{
  width: 100%;
  @include res-down-high(sm){
    max-height: calc(100vh - 180px);
    height: auto;
    width: 100%;
    object-fit: cover;
  }
  @media (min-height: 800px) and (orientation: portrait) {
    height:calc(100vh - 400px)
  }
}

.main_home{
  min-height: calc(100vh - 77px);
  display: flex;
  align-items: center;
  flex-direction: column;
  .article{
    display: flex;
    flex-direction: column;
    width: 100vw;
    .heroimg{
      display: flex;
      margin: 0 0 25px;
      position: relative;
      flex-direction: column;
      video{
        width: 100%;
        @include res-down-high(sm){
          max-height: calc(100vh - 180px);
          height: auto;
          width: 100%;
          object-fit: cover;
        }
        @media (min-height: 800px) and (orientation: portrait) {
          height:calc(100vh - 400px)
        }
      }
      img{
        // max-width: 100%;
        width: 100%;
        @include res-down-high(sm){
          max-height: calc(100vh - 180px);
          height: auto;
          width: 100%;
          object-fit: cover;
        }
        @media (min-height: 800px) and (orientation: portrait) {
          height:calc(100vh - 400px)
        }
      }
      .btn_register{
        position: absolute;
        bottom: 50px;
        left: 48px;
        right: 48px;
        width: calc(100% - 96px);
        // box-shadow: 0 0 20px 5px rgba(255,255,255,75%);
      }
      figcaption{
        text-align: center;
        margin-top: 1rem;
      }
    }
    .heroimg_fig{
      display: flex;
      margin: 0 0 1rem;
      position: relative;
      flex-direction: column;
      img{
        // max-width: 100%;
        width: 100%;
        @include res-down-high(sm){
          max-height: calc(100vh - 180px);
          height: auto;
          width: 100%;
          object-fit: cover;
        }
        @media (min-height: 800px) and (orientation: portrait) {
          height:calc(100vh - 400px)
        }
      }
      figcaption{
        text-align: center;
        margin-top: 1rem;
      }
    }
    .timer{
      margin: 0 0 25px 0;
      padding: 0;
      >dt,.dt{
        margin: 0;
        padding: 0;
      }
      >dd{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-around;
        .time{
          margin: 10px 0 0 0;
          padding: 0;
          justify-content: center;
          text-align: center;
          dt{
            font-weight: 600;
            font-size: 40px;
            line-height: 1;
            color: #000;
            padding: 0;
            margin-bottom: 15px;
          }
          dd{
            font-weight: 300;
            font-size: 10px;
            line-height: 1;
            color: #000;
            margin: 0;
            padding: 0;
          }
        }
        
      }
      
    }
    .select_wr{
      .select{
        margin-bottom: 10px;
      }
    }
    .feat_slider{
      .feat_slide_img{
        display: flex !important;
      }
      .slick_back{
        svg{
          // @include rtlOnly(transform, rotateY(180deg));
        }
        @include rtl(left,13px ,13px);
        @include rtl(right,unset ,unset);
        display: flex !important;
        position: absolute;
        z-index: 9;
        color:#000;
        background-color: #fff;
        width: 48px;
        height: 48px;
        transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:before{
          display: none;
        }
        &:hover,&:focus{
          color:#fff;
          background-color: #000;
        }
      }
      .slick_next{
        svg{
          // @include rtlOnly(transform, rotateY(180deg));
        }
        @include rtl(right,13px ,13px);
        @include rtl(left,unset ,unset );
        display: flex !important;
        position: absolute;
        z-index: 9;
        color:#000;
        background-color: #fff;
        width: 48px;
        height: 48px;
        transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:before{
          display: none;
        }
        &:hover,&:focus{
          color:#fff;
          background-color: #000;
        }
      }
    }
    .iframe_wr{
      width: 100%;
      height: calc(100vh - 80px);
      iframe{
        width: 100%;
        height: calc(100vh - 80px);
      }
    }
  }
}
.hair_about_comp_heroimg{
  display: flex;
  margin: 0 0 35px;
  flex-direction: column;
}
.dashboard_btn_wr{
  .dashboard_btn{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    .icon_wr{
      margin-bottom: 5px;
      @include rtlOnly(transform,scaleX(-1));
      .svg{
        max-width: 42px;
        max-height: 46px;
        height: auto;
      }
    }
    .text{
      display: flex;
      line-height: 1.23;
      font-size: 13px;
      @include rtl(letter-spacing ,0.08em,0);
      text-align: center;
      color: #fff;
    }
  }
}

.common_main{
  min-height: calc(100vh - 77px);
  display: flex;
  align-items: center;
  overflow-x: auto;
  p{
    font-size: 0.875rem;
    overflow-wrap: anywhere;
  }
  ul,ol{
    li{
      font-size: 0.875rem;
      margin-bottom: 10px;
    }
  }
}

  