// Value	Meaning
// 0	Original bootstrap (default)
// 1	Everything filpped
// 2	LTR and RTL**

// RTL support
$rtl-support: 2 !default;

// [dir=ltr] & {

// }
// [dir=rtl] & {

// }

@mixin rtl($property, $ltr-value, $rtl-value) {
  [dir=ltr] & {
    #{$property}: $ltr-value;
  }
  [dir=rtl] & {
    #{$property}: $rtl-value;
  }
}

@mixin theme($property, $ltr-value, $rtl-value) {
  .theme-light & {
    #{$property}: $ltr-value;
  }
  .theme-dark & {
    #{$property}: $rtl-value;
  }
}

@mixin ltrOnly($property, $ltr-value) {
  [dir=ltr] & {
    #{$property}: $ltr-value;
  }
}
@mixin rtlOnly($property, $rtl-value) {
  [dir=rtl] & {
    #{$property}: $rtl-value;
  }
}

@mixin rtlValidBgPos($property, $ltr-value1, $ltr-value2, $rtl-value2, $ltr-value3) {
  [dir=ltr] & {
    #{$property}: $ltr-value1 $ltr-value2 $ltr-value3;
  }
  [dir=rtl] & {
    #{$property}: $ltr-value1 $rtl-value2 $ltr-value3;
  }
}





@mixin right($value) {
  @if ($rtl-support == 0) {
    right: $value;
  }
  @else if ($rtl-support == 1) {
    left: $value;
  }
  @else if ($rtl-support == 2) {
    [dir="ltr"] & {
      right: $value;
    }
    [dir="rtl"] & {
      left: $value;
    }
  }
}

@mixin left($value) {
  @if ($rtl-support == 0) {
    left: $value;
  }
  @else if ($rtl-support == 1) {
    right: $value;
  }
  @else if ($rtl-support == 2) {
    [dir="ltr"] & {
      left: $value;
    }
    [dir="rtl"] & {
      right: $value;
    }
  }
}


@mixin padding-left($value) {
  @if ($rtl-support == 0) {
    padding-left: $value;
  }
  @else if ($rtl-support == 1) {
    padding-right: $value;
  }
  @else if ($rtl-support == 2) {
    [dir="ltr"] & {
      padding-left: $value;
    }
    [dir="rtl"] & {
      padding-right: $value;
    }
  }
}

@mixin padding-right($value) {
  @if ($rtl-support == 0) {
    padding-right: $value;
  }
  @else if ($rtl-support == 1) {
    padding-left: $value;
  }
  @else if ($rtl-support == 2) {
    [dir="ltr"] & {
      padding-right: $value;
    }
    [dir="rtl"] & {
      padding-left: $value;
    }
  }
}



@mixin margin-left($value) {
  @if ($rtl-support == 0) {
    margin-left: $value;
  }
  @else if ($rtl-support == 1) {
    margin-right: $value;
  }
  @else if ($rtl-support == 2) {
    [dir="ltr"] & {
      margin-left: $value;
    }
    [dir="rtl"] & {
      margin-right: $value;
    }
  }
}

@mixin margin-right($value) {
  @if ($rtl-support == 0) {
    margin-right: $value;
  }
  @else if ($rtl-support == 1) {
    margin-left: $value;
  }
  @else if ($rtl-support == 2) {
    [dir="ltr"] & {
      margin-right: $value;
    }
    [dir="rtl"] & {
      margin-left: $value;
    }
  }
}


@mixin border-left($value) {
  @if ($rtl-support == 0) {
    border-left: $value;
  }
  @else if ($rtl-support == 1) {
    border-right: $value;
  }
  @else if ($rtl-support == 2) {
    [dir="ltr"] & {
      border-left: $value;
    }
    [dir="rtl"] & {
      border-right: $value;
    }
  }
}

@mixin border-right($value) {
  @if ($rtl-support == 0) {
    border-right: $value;
  }
  @else if ($rtl-support == 1) {
    border-left: $value;
  }
  @else if ($rtl-support == 2) {
    [dir="ltr"] & {
      border-right: $value;
    }
    [dir="rtl"] & {
      border-left: $value;
    }
  }
}

@mixin  border-right-color($value) {
  @if ($rtl-support == 0) {
    border-right-color: $value;
  }
  @else if ($rtl-support == 1) {
    border-left-color: $value;
  }
  @else if ($rtl-support == 2) {
    [dir="ltr"] & {
      border-right-color: $value;
    }
    [dir="rtl"] & {
      border-left-color: $value;
    }
  }
}
@mixin  border-left-color($value) {
  @if ($rtl-support == 0) {
    border-left-color: $value;
  }
  @else if ($rtl-support == 1) {
    border-right-color: $value;
  }
  @else if ($rtl-support == 2) {
    [dir="ltr"] & {
      border-left-color: $value;
    }
    [dir="rtl"] & {
      border-right-color: $value;
    }
  }
}
[dir=rtl] .microsite_main__wDGFO .microsite_article_discover_products__PIkLt .product-item p{
  direction: rtl;
}

//border radius
// @mixin border-top-right-radius($value){
//   @if ($rtl-support == 0) {
//     border-top-right-radius: $value;
//   }
//   @else if ($rtl-support == 1) {
//     border-top-left-radius: $value;
//   }
//   @else if ($rtl-support == 2) {
//     [dir="ltr"] & {
//       border-top-right-radius: $value;
//     }
//     [dir="rtl"] & {
//       border-top-left-radius: $value;
//     }
//   }
// }
// @mixin border-top-left-radius($value){
//   @if ($rtl-support == 0) {
//     border-top-left-radius: $value;
//   }
//   @else if ($rtl-support == 1) {
//     border-top-right-radius: $value;
//   }
//   @else if ($rtl-support == 2) {
//     [dir="ltr"] & {
//       border-top-left-radius: $value;
//     }
//     [dir="rtl"] & {
//       border-top-right-radius: $value;
//     }
//   }
// }
// @mixin border-bottom-right-radius($value){
//   @if ($rtl-support == 0) {
//     border-bottom-right-radius: $value;
//   }
//   @else if ($rtl-support == 1) {
//     border-bottom-left-radius: $value;
//   }
//   @else if ($rtl-support == 2) {
//     [dir="ltr"] & {
//       border-bottom-right-radius: $value;
//     }
//     [dir="rtl"] & {
//       border-bottom-left-radius: $value;
//     }
//   }
// }
// @mixin border-bottom-left-radius($value){
//   @if ($rtl-support == 0) {
//     border-bottom-left-radius: $value;
//   }
//   @else if ($rtl-support == 1) {
//     border-bottom-right-radius: $value;
//   }
//   @else if ($rtl-support == 2) {
//     [dir="ltr"] & {
//       border-bottom-left-radius: $value;
//     }
//     [dir="rtl"] & {
//       border-bottom-right-radius: $value;
//     }
//   }
// }