// @import "variables";
$breakpoints: (
  // xs: 0,
  // sm: 576px,
  // md: 768px,
  // lg: 992px,
  // xl: 1200px
  xs: 0,
  s: 540px,
  sm: 600px,
  md: 1024px,
  mdl: 1280px,
  sd: 1366px,
  lg: 1440px,
  xl: 1600px,
  xxl: 2560px,
) !default;

@mixin theme($property, $light-value, $dark-value, $gray-value) {
  // [data-theme=light] & {
  //   #{$property}: $light-value;
  // }
  // [data-theme=dark] & {
  //   #{$property}: $dark-value;
  // }
  // [data-theme=monochrome] & {
  //   #{$property}: $gray-value;
  // }
}

@mixin load($property, $no, $yes) {
  [data-load=no] & {
    #{$property}: $no;
  }
  [data-load=yes] & {
    #{$property}: $yes;
  }
}

@mixin ios($property, $true, $false) {
  [data-ios=true] & {
    #{$property}: $true;
  }
  [data-ios=false] & {
    #{$property}: $false;
  }
}
@mixin safari($property, $true, $false) {
  [data-safari=true] & {
    #{$property}: $true;
  }
  [data-safari=false] & {
    #{$property}: $false;
  }
}
@mixin ie($property, $true, $false) {
  [data-ie=true] & {
    #{$property}: $true;
  }
  [data-ie=false] & {
    #{$property}: $false;
  }
}

@mixin res-up($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin res-down($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value)) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
@mixin res-down-high($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin res-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
      @content;
    }
  
  // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
@mixin res-between-high($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  
  // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

$orientations: (
  portrait: portrait,
  landscape: landscape
);
// @include portrait-above(sm, md) {}
@mixin ot-up($above, $orientation) {

  // If both the above and orientation exist in the map.
  @if map-has-key($breakpoints, $above) and map-has-key($orientations, $orientation) {

    // Get the above and orientation.
    $above-breakpoint: map-get($breakpoints, $above);
    $orientation-breakpoint: map-get($orientations, $orientation);

    // Write the media query.
    @media (min-width: $above-breakpoint) and (orientation: ($orientation-breakpoint)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  }

  @else {

    // If above breakpoint is invalid.
    @if (map-has-key($breakpoints, $above)==false) {

      // Log a warning.
      @warn 'Your above breakpoint was invalid: #{$above}.';
    }

    // If orientation breakpoint is invalid.
    @if (map-has-key($breakpoints, $orientation)==false) {

      // Log a warning.
      @warn 'Your orientation breakpoint was invalid: #{$orientation}.';
    }
  }
}
// @include portrait-below(sm, md) {}
@mixin ot-down($below, $orientation) {

  // If both the below and orientation exist in the map.
  @if map-has-key($breakpoints, $below) and map-has-key($orientations, $orientation) {

    // Get the below and orientation.
    $below-breakpoint: map-get($breakpoints, $below);
    $orientation-breakpoint: map-get($orientations, $orientation);

    // Write the media query.
    @media (max-width: $below-breakpoint) and (orientation: ($orientation-breakpoint)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  }

  @else {

    // If below breakpoint is invalid.
    @if (map-has-key($breakpoints, $below)==false) {

      // Log a warning.
      @warn 'Your below breakpoint was invalid: #{$below}.';
    }

    // If orientation breakpoint is invalid.
    @if (map-has-key($breakpoints, $orientation)==false) {

      // Log a warning.
      @warn 'Your orientation breakpoint was invalid: #{$orientation}.';
    }
  }
}

@include res-up(lg){
 
}
@include res-between(md,lg){
 
}
@include res-between(sm,md){
  
}
@include res-down-high(sm){
  
}

@include ot-up(sm, portrait){
  
}