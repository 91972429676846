@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../../assets/fonts/PlayfairDisplay-Regular.eot');
  src: url('../../assets/fonts/PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'), 
      url('../../assets/fonts/PlayfairDisplay-Regular.woff2') format('woff2'), 
      url('../../assets/fonts/PlayfairDisplay-Regular.woff') format('woff'), 
      url('../../assets/fonts/PlayfairDisplay-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../../assets/fonts/PlayfairDisplay-Bold.eot');
  src: url('../../assets/fonts/PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'), 
      url('../../assets/fonts/PlayfairDisplay-Bold.woff2') format('woff2'), 
      url('../../assets/fonts/PlayfairDisplay-Bold.woff') format('woff'), 
      url('../../assets/fonts/PlayfairDisplay-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'NotoSans';
  src: url('../../assets/fonts/NotoSans-Regular.eot');
  src: url('../../assets/fonts/NotoSans-Regular.eot?#iefix') format('embedded-opentype'), 
      url('../../assets/fonts/NotoSans-Regular.woff2') format('woff2'), 
      url('../../assets/fonts/NotoSans-Regular.woff') format('woff'), 
      url('../../assets/fonts/NotoSans-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'NotoSans';
  src: url('../../assets/fonts/NotoSans-Bold.eot');
  src: url('../../assets/fonts/NotoSans-Bold.eot?#iefix') format('embedded-opentype'), 
      url('../../assets/fonts/NotoSans-Bold.woff2') format('woff2'), 
      url('../../assets/fonts/NotoSans-Bold.woff') format('woff'), 
      url('../../assets/fonts/NotoSans-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

//arabic
@font-face {
  font-family: 'GE_SS_Two';
  src: url('../../assets/fonts/GE_SS_Two_Regular.eot');
  src: url('../../assets/fonts/GE_SS_Two_Regular.eot?#iefix') format('embedded-opentype'), 
      url('../../assets/fonts/GE_SS_Two_Regular.woff2') format('woff2'), 
      url('../../assets/fonts/GE_SS_Two_Regular.woff') format('woff'), 
      url('../../assets/fonts/GE_SS_Two_Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'GE_SS_Two';
  src: url('../../assets/fonts/GE_SS_Two_Bold.eot');
  src: url('../../assets/fonts/GE_SS_Two_Bold.eot?#iefix') format('embedded-opentype'), 
      url('../../assets/fonts/GE_SS_Two_Bold.woff2') format('woff2'), 
      url('../../assets/fonts/GE_SS_Two_Bold.woff') format('woff'), 
      url('../../assets/fonts/GE_SS_Two_Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}